import {MyBlog} from "./components"
import './App.css';

function App() {
  return (
    <div>
      <MyBlog/>
    </div>
  );
}

export default App;
